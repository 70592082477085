@import 'styles/theme/theme';

.drawer {
  min-height: 200px;
  max-height: 70%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;

  .hideHeaderBorder {
    border-bottom: 0 !important;
  }

  &__content {
    overflow: auto;
    height: 100%;
  }
}

.title {
  display: flex;
  align-items: center;
}

.leftIcon {
  margin-right: 6px;
}

.drawer .header {
  position: relative;
  padding: 16px 16px;
  display: flex;
  align-content: center;
  border-bottom: 1px solid $tertiaryGrey;
  justify-content: space-between;
  &_text {
    width: 100%;
    display: flex;
    &_container {
      padding-left: 5px;
    }
    .subTitle {
      margin-top: 4px;
      display: block;
    }
  }
  &_closeIcon {
    min-width: 0;
    padding: 0;
    &:hover,
    &:active {
      background-color: $white;
      box-shadow: none;
    }
  }
}
