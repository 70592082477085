@import './styleConstants';
@import './mixins';

@font-face {
  font-family: 'RobotoLight';
  src: local('Roboto Light'), local('Roboto-Light'), url('../public/fonts/Roboto-Light.ttf');
  font-weight: lighter;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoBold';
  src: local('Roboto Bold'), local('Roboto-Bold'), url('../public/fonts/Roboto-Bold.ttf');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoMedium';
  src: local('Roboto Medium'), local('Roboto-Medium'), url('../public/fonts/Roboto-Medium.ttf');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoItalic';
  src: local('Roboto Italic'), local('Roboto-Italic'), url('../public/fonts/Roboto-Italic.ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'RobotoCondensed';
  src: local('Roboto Condensed'), local('Roboto-Condensed'), url('../public/fonts/RobotoCondensed-Regular.ttf');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Material Icons';
  src: local('Material Icons'), local('Material-Icons'), url('../public/fonts/Material-Icons.woff2');
  font-style: normal;
  font-weight: 400;
}

html {
  height: 100vh;
}

body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  position: relative;
  font-size: $fz-basic;
  color: $color-text;
  line-height: 1.43;
  letter-spacing: 0.01071em;

  &.show_tracked__artist .ob-artist {
    background-color: #f0e68c; // khaki
    border-bottom: 2px solid red;
    &.ob-section {
      border-bottom: 1px solid green;
    }
  }
  &.show_tracked__organization .ob-organization {
    background-color: #add8e6; // lightblue
    border-bottom: 2px solid red;
    &.ob-section {
      border-bottom: 1px solid green;
    }
  }
  &.show_tracked__competition .ob-competition {
    background-color: #ffb6c1; // lightpink
    border-bottom: 2px solid red;
    &.ob-section {
      border-bottom: 1px solid green;
    }
  }
  &.show_tracked__production .ob-production {
    background-color: #87cefa3d; // lightskyblue
    border-bottom: 2px solid red;
    &.ob-section {
      border-bottom: 1px solid green;
    }
  }
  &.show_tracked__work .ob-work {
    background-color: #f08080; // lightcoral
    border-bottom: 2px solid red;
    &.ob-section {
      border-bottom: 1px solid green;
    }
  }
  &.show_tracked__venue .ob-venue {
    background-color: #e0ffff; // lightcyan
    border-bottom: 2px solid red;
    &.ob-section {
      border-bottom: 1px solid green;
    }
  }
  &.show_tracked__performance .ob-performance {
    background-color: #ffa07a; // lightsalmon
    border-bottom: 2px solid red;
    &.ob-section {
      border-bottom: 1px solid green;
    }
  }
  &.show_tracked__manager .ob-manager {
    background-color: #ff69b4; // hotpink
    border-bottom: 2px solid red;
    &.ob-section {
      border-bottom: 1px solid green;
    }
  }
  .show_tracked__click-success {
    border: 1px solid green !important;
    outline: 1px solid green !important;
  }
  .show_tracked__click-error {
    border: 2px solid red !important;
    outline: 2px solid red !important;
  }
  &.show_tracked__manager .ob-manager {
    background-color: #90ee90; // lightgreen
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Playfair Display', 'serif';
  font-weight: 300;
  margin: 0;
}

// h1 {
//   background-color: yellow;
// }

// h2 {
//   background-color: red;
// }

// h3 {
//   background-color: green;
// }

// for operabase html translations
a,
a > strong {
  color: #0491ff;
  text-decoration: none;
  &:hover {
    color: #174d78;
  }
}

b {
  font-weight: 500 !important;
}

.slick-slider {
  div {
    line-height: 0;
  }
}

#has-cookie #hubspot-messages-iframe-container {
  bottom: 200px !important;
}

@media (min-width: 480px) {
  #has-cookie #hubspot-messages-iframe-container {
    bottom: 180px !important;
  }
}
@media (min-width: 768px) {
  #has-cookie #hubspot-messages-iframe-container {
    bottom: 150px !important;
  }
}
//nprogress overrides
#nprogress .bar {
  background: rgb(243, 109, 42) !important;
  height: 4px !important;
}
#nprogress .peg {
  box-shadow: 0 0 10px rgb(243, 109, 42), 0 0 5px rgb(243, 109, 42) !important;
}
#nprogress .spinner-icon {
  border-top-color: rgb(243, 109, 42) !important;
  border-left-color: rgb(243, 109, 42) !important;
}

.pac-container {
  z-index: 99999;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes focus-in {
  0% {
    filter: blur(50px);
    transform: scale(0.8);
  }
  50% {
    filter: blur(40px);
  }
  100% {
    filter: blur(0);
    transform: scale(1);
  }
}

.animated-background {
  animation-duration: 1.6s;
  animation-fill-mode: forwards;
  animation-iteration-count: 20;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: transparent;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  position: relative;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

// to remove manage settings link and cookiebot heading from cookiebot banner
a#CybotCookiebotDialogPoweredbyCybot,
#CybotCookiebotDialogBodyEdgeMoreDetails {
  display: none !important;
}
