@use 'styles/theme/theme' as theme;

.icon {
  width: 18px;
  height: 18px;
}
.successIcon {
  fill: theme.$green;
}
.errorIcon {
  fill: theme.$red;
}
.warningIcon {
  fill: theme.$orange;
}

.closeIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 6px;
}
.iconContainer {
  display: flex;
  align-items: center;
  margin-right: 6px;
}

.message {
  flex: 1;
  color: theme.$primaryGrey;
}

.snackbar {
  background-color: theme.$orangeLight;
  border-radius: 58px;
  padding: 12px 18px;
  min-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.snackbarRoot {
  top: 100px !important; // NOTE: important required to override snackbar internal class
}

.actionContainer {
  margin-left: 8px;
  cursor: pointer;
  z-index: 100001;
}

.snackbarContainer{
  z-index: 100001 !important;
}