@use 'styles/theme/theme' as theme;

.btn {
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: middle;
  font-family: theme.$base-font-family;
  position: relative;
  font-style: normal;
  font-weight: theme.$font-weight-medium;
  font-size: theme.$font-size-14;
  line-height: 20px;
  border: none;
  cursor: pointer;
  min-width: 100px;
  white-space: nowrap;
  outline: none;

  .icon {
    display: inherit;
    vertical-align: text-bottom;

    & > svg {
      width: 16px;
      height: 16px;
    }
  }

  .loader {
    position: absolute;
    color: theme.$blue;
  }

  & > .leftIcon {
    margin-right: 6px;
  }

  & > .rightIcon {
    margin-left: 6px;
  }
}

.shape {
  &__rounded {
    border-radius: 38px;
  }
  &__rectangle {
    border-radius: 6px;
  }
}

.size {
  &__large {
    padding: 10px 12px;
  }
  &__medium {
    padding: 4px 8px;
  }
}

.primary {
  background: theme.$blue;
  border: 1px solid theme.$blue;
  color: theme.$white;

  .icon svg {
    fill: theme.$white;
  }

  &:hover {
    box-shadow: theme.$hoverBoxShadow;
    color: theme.$white;
  }

  &:active {
    box-shadow: theme.$activeBoxShadow;
    color: theme.$white;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  & > .loader {
    color: theme.$white;
  }
}

.orange {
  background: theme.$orange;
  border: 1px solid theme.$orange;
  color: theme.$white;

  .icon svg {
    fill: theme.$white;
  }

  &:hover {
    color: theme.$white;
    box-shadow: theme.$hoverBoxShadow;
  }

  &:active {
    color: theme.$white;
    box-shadow: theme.$activeBoxShadow;
  }

  &.active {
    color: theme.$white;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  & > .loader {
    color: theme.$white;
  }
}

.dark {
  background: theme.$primaryGrey;
  border: 1px solid theme.$secondaryGrey;
  color: theme.$white;
  .icon svg {
    fill: theme.$white;
  }

  &:hover {
    color: theme.$white;
    border: 1px solid theme.$secondaryGrey;
    background: theme.$primaryGrey;
    .icon svg {
      fill: theme.$white;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }
}

.grey {
  background: theme.$white;
  border: 1px solid theme.$primaryGrey;
  color: theme.$primaryGrey;
  .icon svg {
    fill: theme.$primaryGrey;
  }

  &:hover {
    color: theme.$white;
    box-shadow: theme.$btnBoxShadowHover;
    border: 1px solid theme.$primaryGrey;
    background: theme.$primaryGrey;
    .icon svg {
      fill: theme.$white;
    }
  }

  &:active {
    color: theme.$white;
    box-shadow: theme.$btnBoxShadowActive;
    border: 1px solid theme.$primaryGrey;
  }

  &.active {
    box-shadow: none;
    color: theme.$white;
    border: 1px solid theme.$primaryGrey;
    background: theme.$primaryGrey;
    .icon svg {
      fill: theme.$white;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: default;
    box-shadow: none;
    background: theme.$white;
    border: 1px solid theme.$primaryGrey;
    pointer-events: none;
  }
}

.overlay {
  background: rgba(theme.$black, 0.6);
  border: 1px solid rgba(theme.$black, 0.6);
  color: theme.$white;

  .icon svg {
    fill: theme.$white;
  }

  &:hover {
    border-color: theme.$white;
    color: theme.$white;
  }

  &:active {
    color: theme.$white;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  & > .loader {
    color: theme.$white;
  }
}

.secondary {
  background: theme.$white;
  border: 1px solid theme.$tertiaryGrey;
  color: theme.$blue;
  .icon svg {
    fill: theme.$blue;
  }

  &:hover {
    color: theme.$blue;
    box-shadow: theme.$hoverBoxShadow;
    border: 1px solid theme.$blue;
    background: theme.$white;
    .icon svg {
      fill: theme.$blue;
    }
  }

  &:active {
    color: theme.$blue;
    box-shadow: theme.$activeBoxShadow;
    border: 1px solid theme.$blue;
  }

  &.active {
    box-shadow: none;
    color: theme.$blue;
    border: 1px solid theme.$blue;
    background: theme.$white;
    .icon svg {
      fill: theme.$blue;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: default;
    box-shadow: none;
    background: theme.$white;
    border: 1px solid theme.$tertiaryGrey;
    pointer-events: none;
  }
}

.tertiary {
  background: transparent;
  color: theme.$primaryGrey;
  .icon svg {
    fill: theme.$primaryGrey;
  }

  &:hover {
    color: theme.$blue;
    box-shadow: theme.$hoverBoxShadow;
    background: theme.$blueLight;
    border: none;

    .icon svg {
      fill: theme.$blue;
    }
  }

  &:active {
    color: theme.$blue;
    box-shadow: theme.$activeBoxShadow;
  }

  &.active {
    color: theme.$blue;
    box-shadow: none;
    background: theme.$blueLight;
    border: none;
    .icon svg {
      fill: theme.$blue;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    color: theme.$primaryGrey;
    cursor: default;
    box-shadow: none;
    background: theme.$white;
    pointer-events: none;
    .icon svg {
      fill: theme.$primaryGrey;
    }
  }

  .icon svg {
    fill: theme.$primaryGrey;
  }
}

.quaternary {
  background: transparent;
  color: theme.$blue;
  .icon svg {
    fill: theme.$blue;
  }

  &:hover {
    color: theme.$white;
    box-shadow: theme.$hoverBoxShadow;
    background: theme.$blue;
    border: none;
    .icon svg {
      fill: theme.$white;
    }
  }

  &:active {
    color: theme.$white;
    box-shadow: theme.$activeBoxShadow;
  }

  &.active {
    box-shadow: none;
    color: theme.$white;
    box-shadow: theme.$hoverBoxShadow;
    background: theme.$blue;
    border: none;
    .icon svg {
      fill: theme.$white;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: default;
    box-shadow: none;
    background: transparent;
    pointer-events: none;
    .icon svg {
      fill: theme.$white;
    }
  }

  & > .loader {
    color: theme.$white;
  }

  .icon svg {
    fill: theme.$blue;
  }
}

.cuetv {
  background: theme.$red;
  border: 1px solid theme.$red;
  color: theme.$white;
  .icon svg {
    fill: theme.$white;
  }

  &:hover {
    color: theme.$white;
    box-shadow: theme.$hoverCueTvBoxShadow;
  }

  &:active {
    color: theme.$white;
    box-shadow: theme.$activeBoxShadow;
  }

  &.active {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }
}

.text {
  font-size: inherit;
  text-decoration: none;
  line-height: 1.3;
  color: theme.$blue;
  padding: 0;
  min-width: unset;
  vertical-align: baseline;

  .icon svg {
    fill: theme.$blue;
  }

  &:hover {
    outline: 0;
    color: theme.$blue;
    text-decoration: underline;
    .icon svg {
      fill: theme.$blue;
    }
  }

  &:active {
    outline: 0;
    color: theme.$blue;
  }

  &.active {
    outline: 0;
    color: theme.$blue;
    text-decoration: underline;
    .icon svg {
      fill: theme.$blue;
    }
  }

  &:focus {
    outline: 0;
    color: theme.$blue;
  }

  &.disabled,
  &:disabled {
    cursor: default;
    color: theme.$tertiaryGrey;
    pointer-events: none;
  }
}

.disableUnderline {
  &:hover {
    text-decoration: none;
  }
}

.disableHoverEffect {
  &:hover {
    color: inherit;
  }
}

.overrideButtonStyles {
  border-radius: 0;
  box-shadow: none;
}

.iconLink {
  display: inline-flex;
  align-items: center;
}

.inline {
  white-space: break-spaces;
  display: inline;
}

.native {
  position: relative;
  .loader {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }
}

.wrap {
  white-space: normal;
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &.center {
    text-align: center;
  }
}

.stretched {
  width: 100%;
  overflow: hidden;
}
