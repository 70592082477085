.desktopViewContainer {
  max-height: 100% !important;
  border-radius: unset;

  &__width_375px {
    width: 375px;
  }
}

.mobileViewContainer {
  height: 95%;
  max-height: 95% !important;
}

.drawerContent {
  height: 100%;
}

.entityViewContainer {
  min-height: 90% !important;
  max-height: 90% !important;
  bottom: 0;
  transform: translateY(100%);
  animation: slideUp 0.5s forwards ease-in-out;

  &__headerText {
    margin-left: auto;
    max-width: 1264px;
  }

  &__headerCloseIcon {
    margin-right: auto;
  }

  &__drawerContent {
    & > * {
      pointer-events: none;
    }
  }

  &__drawerRoot {
    z-index: 100000;
  }

  @keyframes slideUp {
    to {
      transform: translateY(0);
    }
  }
}
