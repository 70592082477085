@import 'styles/theme/theme';

.icon {
  height: 18px;
  width: 18px;
  line-height: 1 !important;
  vertical-align: middle;
  fill: $secondaryGrey;

  &.hover {
    .hoverIcon {
      display: none;
    }

    &:hover {
      .baseIcon {
        display: none;
      }

      .hoverIcon {
        display: block;
      }
    }
  }

  &.active {
    .activeIcon {
      display: none;
    }

    &:active {
      .baseIcon {
        display: none;
      }

      .activeIcon {
        display: block;
      }
    }

    &.hover:active {
      .hoverIcon {
        display: none;
      }
    }
  }

  &.clickable {
    cursor: pointer;
  }
}