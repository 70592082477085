@use 'styles/theme/theme' as *;

.btn {
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: middle;
  font-family: $base-font-family;
  position: relative;
  font-style: normal;
  font-weight: $font-weight-medium;
  font-size: $font-size-14;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  outline: none;

  .icon {
    display: inherit;

    & > svg {
      width: 16px;
      height: 16px;
    }
  }

  .loader {
    position: absolute;
    color: $blue;
  }

  & > .leftIcon {
    margin-right: 4px;
  }

  & > .rightIcon {
    margin-left: 4px;
  }
}

.shape {
  &__rounded {
    border-radius: 64px;
  }
  &__rectangle {
    border-radius: 4px;
  }
}

.size {
  &__large {
    padding: 12px 15px;
    &.iconBtn {
      padding: 12px;
    }
    line-height: 16px;
    .icon svg {
      width: 16px;
      height: 16px;
    }
  }
  &__medium {
    padding: 8px 15px;
    &.iconBtn {
      padding: 8px;
    }
    line-height: 16px;
    .icon svg {
      width: 16px;
      height: 16px;
    }

    @media screen and (max-width: 900px) {
      font-size: 12px;
      .icon svg {
        width: 12px;
        height: 12px;
      }
    }

    &__compact {
      padding: 1px;
      font-size: 12px;
      line-height: 12px;
    }
  }
  &__small {
    padding: 5px 11px;
    &.iconBtn {
      padding: 5px;
    }
    font-size: 12px;
    line-height: 12px;
    .icon svg {
      width: 12px;
      height: 12px;
    }
  }
}

.primary {
  background: $blue;
  border: 1px solid $blue;
  color: $white;

  .icon svg {
    fill: $white;
  }

  &.hoverable:hover {
    color: $white;
    background: $blue6;
  }

  &:active {
    color: $white;
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    box-shadow: none;
    pointer-events: none;
  }

  & > .loader {
    color: #d9d9d9;
  }

  &.loading {
    // TODO Check button logic for if there could be a case where we are loading and button is not disabled
    opacity: 1;
  }
}

.orange {
  background: $orangeLight;
  border: 1px solid $orange;
  color: $primaryGrey;

  .icon svg {
    fill: $primaryGrey;
  }

  &.hoverable:hover {
    background: $orange1;
  }

  &:active {
    background: $orangeLight;
  }

  &.active {
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  & > .loader {
    color: $grey8;
  }
}

.grey {
  background: $white;
  border: 1px solid $primaryGrey;
  color: $primaryGrey;
  .icon svg {
    fill: $primaryGrey;
  }

  &.hoverable:hover {
    color: $white;
    box-shadow: $btnBoxShadowHover;
    border: 1px solid $primaryGrey;
    background: $primaryGrey;
    .icon svg {
      fill: $white;
    }
  }

  &:active {
    color: $white;
    box-shadow: $btnBoxShadowActive;
    border: 1px solid $primaryGrey;
  }

  &.active {
    box-shadow: none;
    color: $white;
    border: 1px solid $primaryGrey;
    background: $primaryGrey;
    .icon svg {
      fill: $white;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    box-shadow: none;
    background: $white;
    border: 1px solid $primaryGrey;
    pointer-events: none;
  }
}

.dark {
  background: $primaryGrey;
  border: 1px solid $secondaryGrey;
  color: $white;
  .icon svg {
    fill: $white;
  }

  &.hoverable:hover {
    color: $white;
    border: 1px solid $secondaryGrey;
    background: $primaryGrey;
    .icon svg {
      fill: $white;
    }
  }

  &:active {
  }

  &.active {
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  & > .loader {
    color: $white;
  }
}

.dark-gold {
  background: linear-gradient(90deg, $blackGrey3 0%, $primaryGrey 100%);
  border: 1px solid $secondaryGrey;
  color: $yellow2;
  .icon svg {
    fill: $yellow2;
  }

  &.hoverable:hover {
    color: $yellow2;
    border: 1px solid $yellow2;
    background: linear-gradient(90deg, $blackGrey3 0%, $primaryGrey 100%);
    .icon svg {
      fill: $yellow2;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  & > .loader {
    color: $yellow2;
  }
}

.overlay {
  background: rgba($black, 0.6);
  border: 1px solid rgba($black, 0.6);
  color: $white;

  .icon svg {
    fill: $white;
  }

  &.hoverable:hover {
    border-color: $white;
    color: $white;
  }

  &:active {
    color: $white;
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  & > .loader {
    color: $white;
  }
}

.secondary {
  background: $blueLight2;
  border: 1px solid transparent;
  color: $blue;
  .icon svg {
    fill: $blue;
  }

  &.hoverable:hover {
    color: $blue;
    background: $blueLight4;
    .icon svg {
      fill: $blue;
    }
  }

  &:active {
    color: $blue;
  }

  &.active {
    box-shadow: none;
    color: $blue;
    .icon svg {
      fill: $blue;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  &.loading {
    // TODO Check button logic for if there could be a case where we are loading and button is not disabled
    opacity: 1;
  }
}

.tertiary {
  background: $whiteSmoke;
  border: 1px solid $borderGrey;
  color: $secondaryGrey;
  .icon svg {
    fill: $secondaryGrey;
  }

  &.hoverable:hover {
    color: $primaryGrey;
    background: $darkerSmoke;

    .icon svg {
      fill: $primaryGrey;
    }
  }

  &.size__large {
    background: transparent;
    color: $secondaryGrey;

    &.hoverable:hover {
      background: transparent;
      color: $secondaryGrey;

      .icon svg {
        fill: $secondaryGrey;
      }
    }

    &:active {
      color: $secondaryGrey;
    }
  }

  &.active {
    color: $primaryGrey;
    .icon svg {
      fill: $primaryGrey;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    color: $primaryGrey;
    cursor: default;
    pointer-events: none;
    .icon svg {
      fill: $primaryGrey;
    }
  }

  .icon svg {
    fill: $primaryGrey;
  }

  & > .loader {
    color: $grey8;
  }
}

.quaternary {
  background: transparent;
  color: $primaryGrey;
  .icon svg {
    fill: $primaryGrey;
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    box-shadow: none;
    background: transparent;
    pointer-events: none;
  }

  & > .loader {
    color: $primaryGrey;
  }
}

.cuetv {
  background: $red;
  border: 1px solid $red;
  color: $white;
  .icon svg {
    fill: $white;
  }

  &.hoverable:hover {
    color: $white;
    box-shadow: $hoverCueTvBoxShadow;
  }

  &:active {
    color: $white;
    box-shadow: $activeBoxShadow;
  }

  &.active {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }
}

.text {
  font-size: inherit;
  text-decoration: none;
  line-height: 1.3;
  color: $blue;
  padding: 0;
  min-width: unset;
  vertical-align: baseline;

  .icon svg {
    fill: $blue;
  }

  & span {
    line-height: 1.3;
  }

  &.hoverable:hover {
    outline: 0;
    color: $blue;
    text-decoration: underline;
    .icon svg {
      fill: $blue;
    }
  }

  &:active {
    outline: 0;
    color: $blue;
  }

  &.active {
    outline: 0;
    color: $blue;
    text-decoration: underline;
    .icon svg {
      fill: $blue;
    }
  }

  &:focus {
    outline: 0;
    color: $blue;
  }

  &.disabled,
  &:disabled {
    cursor: default;
    color: $tertiaryGrey;
    pointer-events: none;
  }
}

.disableUnderline {
  &:hover {
    text-decoration: none !important;
  }
}

.disableHoverEffect {
  &:hover {
    color: inherit;
  }
}

.overrideButtonStyles {
  border-radius: 0;
  box-shadow: none;
}

.iconLink {
  display: inline-flex;
  align-items: center;
}

.inline {
  white-space: break-spaces;
  display: inline;
}

.native {
  position: relative;
  .loader {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }
}

.wrap {
  white-space: normal;
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &.center {
    text-align: center;
  }
}

.mediaWidgetPadding {
  padding-right: 8px;
}

.stretched {
  width: 100%;
  overflow: hidden;
}
