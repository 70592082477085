// Specifying expected initial heights (to avoid layout shifts), and pre-defined values for global vars
// Values that are being changed by JS MUST BE marked by a comment for clarity as they can cause layout shifts

// P.S. utils/css.js has a hook to assist with auto height update

:root {

  // Vertical offsets (if possible - list in order of appearance, please)
  --header-height: 68px;
  --header-nav-height: 25px;
  --search-bar-height: 0px;
  --banner-height: 0px; // JS Controlled (Content wrap changing height)
  --breadcrumbs-height: 33px;

  @media screen and (max-width: 900px) {
    --header-height: 51px;
    --header-nav-height: 0px;
    --search-bar-height: 50px;
  }

  --header-total-height: calc(var(--header-height) + var(--header-nav-height) + var(--search-bar-height));
  --page-content-static-offset: calc(var(--header-total-height) + var(--banner-height));
}
