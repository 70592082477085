@import 'styles/theme/theme';

.typography-light-20 {
  @include typography($color: none, $size: $font-size-20, $weight: $font-weight-light);
}

.typography-light-16 {
  @include typography($color: none, $size: $font-size-16, $weight: $font-weight-light);
}

.typography-light-14 {
  @include typography($color: none, $size: $font-size-14, $weight: $font-weight-light);
}

.typography-light-12 {
  @include typography($color: none, $size: $font-size-12, $weight: $font-weight-light);
}

.typography-regular-40 {
  @include typography($color: none, $size: $font-size-40, $weight: $font-weight-regular);
}

.typography-regular-28 {
  @include typography($color: none, $size: $font-size-28, $weight: $font-weight-regular);
}

.typography-regular-32 {
  @include typography($color: none, $size: $font-size-32, $weight: $font-weight-regular);
}

.typography-regular-24 {
  @include typography($color: none, $size: $font-size-24, $weight: $font-weight-regular);
}

.typography-regular-20 {
  @include typography($color: none, $size: $font-size-20, $weight: $font-weight-regular);
}

.typography-regular-18 {
  @include typography($color: none, $size: $font-size-18, $weight: $font-weight-regular);
}

.typography-regular-16 {
  @include typography($color: none, $size: $font-size-16, $weight: $font-weight-regular);
}

.typography-regular-14 {
  @include typography($color: none, $size: $font-size-14, $weight: $font-weight-regular);
}

.typography-regular-13 {
  @include typography($color: none, $size: $font-size-13, $weight: $font-weight-regular);
}

.typography-regular-12 {
  @include typography($color: none, $size: $font-size-12, $weight: $font-weight-regular);
}

.typography-regular-9 {
  @include typography($color: none, $size: $font-size-9, $weight: $font-weight-regular);
}

.typography-regular-10 {
  @include typography($color: none, $size: $font-size-10, $weight: $font-weight-regular);
}

.typography-medium-10 {
  @include typography($color: none, $size: $font-size-10, $weight: $font-weight-medium);
}

.typography-regular-11 {
  @include typography($color: none, $size: $font-size-11, $weight: $font-weight-regular);
}

.typography-medium-50 {
  @include typography($color: none, $size: $font-size-50, $weight: $font-weight-medium);
}

.typography-medium-28 {
  @include typography($color: none, $size: $font-size-28, $weight: $font-weight-medium);
}

.typography-medium-32 {
  @include typography($color: none, $size: $font-size-32, $weight: $font-weight-medium);
}

.typography-medium-24 {
  @include typography($color: none, $size: $font-size-24, $weight: $font-weight-medium);
}

.typography-medium-20 {
  @include typography($color: none, $size: $font-size-20, $weight: $font-weight-medium);
}

.typography-medium-18 {
  @include typography($color: none, $size: $font-size-18, $weight: $font-weight-medium);
}

.typography-medium-16 {
  @include typography($color: none, $size: $font-size-16, $weight: $font-weight-medium);
}

.typography-medium-14 {
  @include typography($color: none, $size: $font-size-14, $weight: $font-weight-medium);
}

.typography-medium-13 {
  @include typography($color: none, $size: $font-size-13, $weight: $font-weight-medium);
}

.typography-medium-12 {
  @include typography($color: none, $size: $font-size-12, $weight: $font-weight-medium);
}

.typography-upper-medium-12 {
  @include typography($color: none, $size: $font-size-12, $weight: $font-weight-upper-medium);
}

.typography-medium-11 {
  @include typography($color: none, $size: $font-size-11, $weight: $font-weight-medium);
}

.typography-bold-32 {
  @include typography($color: none, $size: $font-size-32, $weight: $font-weight-bold);
}

.typography-bold-24 {
  @include typography($color: none, $size: $font-size-24, $weight: $font-weight-bold);
}

.typography-bold-20 {
  @include typography($color: none, $size: $font-size-20, $weight: $font-weight-bold);
}

.typography-bold-18 {
  @include typography($color: none, $size: $font-size-18, $weight: $font-weight-bold);
}

.typography-bold-16 {
  @include typography($color: none, $size: $font-size-16, $weight: $font-weight-bold);
}

.typography-bold-15 {
  @include typography($color: none, $size: $font-size-15, $weight: $font-weight-bold);
}

.typography-bold-14 {
  @include typography($color: none, $size: $font-size-14, $weight: $font-weight-bold);
}

.typography-bold-13 {
  @include typography($color: none, $size: $font-size-13, $weight: $font-weight-bold);
}

.typography-bold-12 {
  @include typography($color: none, $size: $font-size-12, $weight: $font-weight-bold);
}

.typography-bold-11 {
  @include typography($color: none, $size: $font-size-11, $weight: $font-weight-bold);
}

.typography-bold-8 {
  @include typography($color: none, $size: $font-size-8, $weight: $font-weight-bold);
}

.typography-bold-11 {
  @include typography($color: none, $size: $font-size-11, $weight: $font-weight-bold);
}

.typography-bold-28 {
  @include typography($color: none, $size: $font-size-28, $weight: $font-weight-bold);
}

.typography-highlight {
  background-color: $yellow;
  color: inherit;
}

.typography-inline {
  display: inline;
}

.typography-secondary-font {
  font-family: $secondary-font-family;
}

.typography-color {
  &-primary {
    color: $primaryGrey;
  }
  &-secondary {
    color: $secondaryGrey;
  }
  &-territory {
    // TODO: Typo | remove this
    color: $tertiaryGrey;
  }
  &-tertiary {
    color: $tertiaryGrey;
  }
  &-quaternary {
    color: $borderGrey;
  }
  &-white {
    color: $white;
  }
  &-link {
    color: $blue;
  }
  &-inherit {
    color: inherit;
  }
  &-orange {
    color: $orange;
  }
  &-error {
    color: $red-error;
  }
  &-red {
    color: $red;
  }
  &-cancelled {
    color: $red-cancelled-dark;
  }
}

.is-italic {
  font-style: $font-style-italic;
}

.is-truncated {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.is-blur {
  filter: $blur;
  cursor: pointer;
  a {
    pointer-events: none;
  }
}

.v-align {
  &-top {
    vertical-align: top;
  }
  &-middle {
    vertical-align: middle;
  }
  &-bottom {
    vertical-align: bottom;
  }
}

.typography-align {
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-justify {
    text-align: justify;
  }
}

.typography-transform {
  &-uppercase {
    text-transform: uppercase;
  }
  &-capitalize {
    text-transform: capitalize;
  }
  &-lowercase {
    text-transform: lowercase;
  }
}
