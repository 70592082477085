@use 'styles/theme/theme' as theme;

.modalRoot {
  z-index: 100001 !important; // NOTE: 1 more than login modal
}
.root {
  text-align: center;
  padding: 20px;
  &_isLoading {
    position: relative;
  }
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: rgba(theme.$white, 0.9);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 14px;
}

.actions {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px 20px;
  justify-content: center;
}

.switchLanguage {
  margin-top: 20px;
  &__quick_options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 8px;
    margin: 8px 0;
  }
}