@use 'styles/theme/theme' as globalTheme;

$themes: (
  "dark": (
    "inverse-theme-name": "light",
    "primary-text-color": globalTheme.$white,
    "secondary-text-color": globalTheme.$yellow2,
    "section-header-text-color": globalTheme.$yellow2,
    "cta-text-color": globalTheme.$yellow2,
    "count-text-color": globalTheme.$yellow2,
    "background-color": globalTheme.$grey16,
    "header-border-color": globalTheme.$secondaryGrey,
    "contrast-border-color": globalTheme.$white,
    "card-background-color": globalTheme.$grey15,
    "card-border-color": globalTheme.$borderGrey2,
    "scroll-arrow-background": globalTheme.$darkModeBackgroundGradient,
    "icon-primary-color": globalTheme.$yellow2,
    "icon-secondary-color": globalTheme.$grey8,
  ),
  "light": (
    "inverse-theme-name": "dark",
    "primary-text-color": globalTheme.$primaryGrey,
    "secondary-text-color": globalTheme.$secondaryGrey,
    "section-header-text-color": globalTheme.$primaryGrey,
    "cta-text-color": globalTheme.$blue,
    "count-text-color": globalTheme.$orange,
    "background-color": globalTheme.$white,
    "header-border-color": globalTheme.$tertiaryGrey,
    "contrast-border-color": globalTheme.$orange,
    "card-background-color": globalTheme.$white,
    "card-border-color": globalTheme.$borderGrey,
    "scroll-arrow-background": globalTheme.$white,
    "icon-primary-color": globalTheme.$blue,
    "icon-secondary-color": globalTheme.$secondaryGrey,
  )
);

@each $theme-name, $theme-values in $themes {
  .theme-#{$theme-name} {
    --base-font-family: globalTheme.$base-font-family;
    --secondary-font-family: globalTheme.$secondary-font-family;
    $inverse-theme-name: map-get($theme-values, "inverse-theme-name");
    $inverse-theme: map-get($themes, $inverse-theme-name);

    @each $key, $value in $theme-values {
      @if not map-has-key($inverse-theme, $key) {
        @error "Inverse theme variable '#{$key}' does not exist!";
      }

      @if $key != "inverse-theme-name" {
        --current-theme-#{$key}: #{$value};
        --inverse-theme-#{$key}: #{map-get($inverse-theme, $key)};
      }
    }
  }
}
