.bgBlur {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  top: -120px;
  transform: translateX(-50%);
  left: 50%;
  border-radius: 0;
  z-index: -1;
}
