@import 'styles/theme/theme';

.tooltip {
  font-family: $base-font-family;
  background-color: $black;
  color: $white;
  font-size: $font-size-12;
  font-weight: $font-weight-medium;
  line-height: $line-height-14;
  text-align: center;
  padding: 12px;
  overflow-wrap: break-word;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  &__white {
    background-color: $white;
  }
}

.tooltipPopper {
  z-index: 99999; // Note: Same as Modal
}

.arrow {
  &__white {
    color: $white;
  }
  &__black {
    color: $black;
  }
}

div.title > * {
  color: inherit;
  white-space: inherit;
  > * {
    color: inherit;
  }
}
