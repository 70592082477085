.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 72px;
  height: 72px;
}
.small {
  width: 20px;
  height: 16px;
}
.iconWithText {
  margin-bottom: 12px;
}

.squiggle {
  stroke-dasharray: 550;
  stroke-dashoffset: 550;
  animation: draw 1.2s ease-in-out infinite alternate;
}

.fastAnimation {
  animation: draw 0.6s ease-in-out infinite alternate;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.text {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #282828;
}

.textTitle {
  font-weight: bold;
  line-height: 25px;
}
