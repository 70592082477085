@import 'styles/theme/theme';

.formContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
  .input {
    height: 60px;
    font-size: 18px;
    border: 1px solid $borderGrey;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 20px;
    padding: 0 16px;
  }
  .button {
    width: 200px;
    height: 40px;
  }
}

.showMoreButton {
  margin-top: 16px;
}

.analytics_dashboard {
  max-width: 1280px;
  margin: auto;

  @media screen and (max-width: 1280px) {
    padding: 0 15px;
  }
}

.report {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, calc(50% - 10px));

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_title {
    font-weight: bold;
  }
  &_actions {
    display: flex;
    grid-gap: 16px;
  }
  &_consolidated {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  @media screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.event {
  padding: 8px 8px 8px 5px;
  border-left: 3px solid $primaryGrey;
  margin-left: -8px;
  &:global(.impression) {
    border-left: 3px solid $yellow1;
  }
  &:global(.click) {
    border-left: 3px solid $orange;
  }
  &:global(.pageView) {
    border-left: 3px solid $blue;
  }
  &:global(.actionCallback) {
    border-left: 3px solid $green;
  }
  &_errored {
    background: $redLight !important;
  }
  &_basic {
    cursor: pointer;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 50px auto 196px;
    grid-template-areas: "index details actions";
    &_index {
      grid-area: index;
    }
    &_fingerprintLabel {
      font-size: 10px;
      margin: 0 0 4px;
      font-weight: bold;
    }
    &_details {
      grid-area: details;
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      width: 100%;
      & > div:first-child {
        font-weight: 500;
      }
    }
    &_main {
      display: grid;
      grid-gap: 8px;
      grid-template-columns: repeat(3, (100% / 3));
    }
    &_extra {
      display: flex;
      grid-gap: 8px;
    }
    &_actions {
      grid-area: actions;
      display: grid;
      grid-gap: 8px;
      grid-template-columns: 90px 40px 50px;
      grid-template-areas: "id pro edit" "entityName entityName entityName" "entityName entityName entityName" "fingerprint fingerprint fingerprint";
      text-align: right;
      &__id {
        grid-area: id;
      }
      &__pro {
        grid-area: pro;
      }
      &__edit {
        grid-area: edit;
      }
      &__entityName {
        grid-area: entityName;
      }
      &__fingerprint {
        grid-area: fingerprint;
      }

      @media screen and (max-width: 1280px) {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
      }
    }

    .tag {
      padding: 2px 4px;
      border-radius: 4px;
      background-color: $tagsBackground;
      &_strike {
        text-decoration: line-through;
        opacity: 0.3;
      }
    }

    @media screen and (max-width: 1280px) {
      grid-template-columns: 50px auto;
      grid-template-areas: "index actions" "details details";
    }
  }

  &_raw {
    margin-top: 8px;
    textarea {
      width: 100%;
      height: 300px;
      resize: none;
      outline: 0;
    }
  }
  &:nth-child(even) {
    background-color: $whiteSmoke;
  }
}

.aggregateReport {
  &_row {
    padding: 8px;
    cursor: pointer;
    border: 1px solid $borderGrey;
    font-weight: 500;

    &_data {
      display: grid;
      grid-template-columns: auto 50px;
      &__count {
        text-align: right;
      }
    }

    & + & {
      margin-top: 8px;
    }

    .aggregateReport_row {
      font-weight: normal;
      padding-right: 0;
      border: 0;
      border-left: 1px solid $borderGrey;
      border-bottom: 1px solid $borderGrey;
    }
  }
}

.fingerprintReport {
  &_data {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    margin-top: 12px;
  }
  &_cell {
    padding: 4px;
    border: 1px solid $borderGrey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_title {
      font-weight: bold;
      font-size: 12px;
    }
    &_value {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      grid-gap: 2px;
    }
    &_softWall {
      background: $orange1;
    }
    &_hardWall {
      background: $yellow1;
    }
  }
}