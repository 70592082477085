@use 'styles/theme/theme' as theme;

.root {
  position: fixed;
  z-index: 99999;
  right: 20px;
  bottom: 20px;
  height: 32px;
  width: 32px;
  transition: all 0.3s;

  &.expanded {
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 300px;
    background: theme.$white;
    border-left: 1px solid theme.$borderGrey;
  }
}

.content {
  padding: 10px;
}

.section {
  &_title {
    margin-bottom: 12px;
  }
  & + & {
    margin-top: 20px;
  }
}
.closeIcon {
  position: absolute;
  right: 300px;
}