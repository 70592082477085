@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  height: 20px;
  padding: 0px 2px 0px 2px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
}

.source {
  width: calc(100% - 18px);
  & > a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.credit {
  width: 18px;
  &__icon {
    display: block;
    fill: theme.$white;
  }
}

.creditInformation {
  padding: 8px;
  width: 200px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
}
