@import 'styles/theme/theme';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($black, 0.6);
  z-index: 99999;
  overflow: auto;
  padding: 100px 0;

  &.modalFade {
    animation: fadeIn 300ms 1 linear;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  .modalContainer {
    position: relative;
    width: 670px;
    min-height: 100px;
    background: $white;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    @media screen and (max-width: 900px) {
      opacity: 1;
    }

    @media screen and (max-width: 700px) {
      width: calc(100% - 32px);
    }

    &__header {
      border-bottom: 1px solid $tertiaryGrey;
      padding: 16px 18px;
      display: flex;
      gap: 8px;
      &_wrapper {
        width: calc(100% - 38px);
      }
    }

    .title {
      width: calc(100% - 38px);
    }

    .closeIcon {
      position: absolute;
      right: 20px;
      top: 10px;
      color: $primaryGrey;
      cursor: pointer;
      svg {
        font-size: 18px;
      }
    }
  }

  .modalContent {
    flex: 1;
    overflow-y: auto;
    padding: 0;
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;

    @media screen and (max-width: 900px) {
      border-top: 1px solid transparent;
      box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
    }
  }
}

@keyframes fadeIn {
  0% {
    animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
